/*
 *
 */

import React from 'react';
import Section from 'components/section';
import Hero from 'components/hero';
import Text from 'components/text';
import { useIntl } from 'react-intl';
import Helmet from 'react-helmet';
import Press from 'components/press';


/*
 *
 */

export default function PayconiqBancontact () {

	const intl = useIntl();

	return (
		<React.Fragment>
			<Helmet>
				<title>{intl.formatMessage({ id: 'page.qr-code-menu.meta_title' })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'page.qr-code-menu.meta_desc' })} />
				<meta property="og:image" content={intl.formatMessage({ id: 'global.meta.og_image.qr-code-menu' })} />
				<meta property="og:url" content={intl.formatMessage({ id: 'global.meta.og_url.qr-code-menu' })} />
			</Helmet>
			<Section>
				<Hero
					title={intl.formatMessage({ id: 'page.payconiq.hero.title' })}
					subtitle={intl.formatMessage({ id: 'page.payconiq.hero.subtitle' })}
				/>
			</Section>
			<Section>
			<Text>
				<p>{intl.formatMessage({ id: 'page.payconiq.section.why.p' })}</p>
				<Press
					press={[
						{
							name: 'CentralApp',
							logo: '/img/centralapp-small.png',
						},
						{
							name: 'Payconiq',
							logo: '/img/payconiq.png',
						}
					]}
					/>
			</Text>
			</Section>
			<Section type="no-padding">
				<iframe 
					title={intl.formatMessage({ id: 'page.qr-code-menu.hero.title' })}
					src={intl.formatMessage({ id: 'page.payconiq.google_form' })}
					width="100%" 
					height="3300" 
					frameBorder="0" 
					marginHeight="0" 
					marginWidth="0">Loading…</iframe>
			</Section>
		</React.Fragment>
	);
}