/*
 *
 */
import React from 'react';
import styled from 'styled-components';

/*
 * Styles
 */
const Root = styled.div`
	padding:2rem 0;

	text-align:center;

	@media (${props => props.theme.media.tablet}) {
		padding:2rem 0;
	}
`;

const Title = styled.h1`
	margin:0 0 .35em;

	line-height:1.1em;
	font-size:2.6rem;
	font-weight:500;

	color:#1b83a4;

	@media (${props => props.theme.media.tablet}) {
		margin:0 auto .35em;
		max-width:18em;
		font-size:4rem;
	}
`;

const Subtitle = styled.p`
	margin:0 auto;

	max-width:90%;
	font-size:1.5rem;
	font-weight:200;
	line-height:1.5em;

	@media (${props => props.theme.media.tablet}) {
		font-size:1.8rem;
		max-width:75%;
	}
`;

const LinkCta = styled.a`


	margin-top:2rem;
	display:inline-block;

	font-size:1.55rem;
	font-weight:500;
	
	color:#fff !important;
	background:#1b83a4;
	padding:1.75rem 3.6rem;
	border-radius:.35rem;
	text-decoration:none;
	transition:0.2s;

	&:hover {
		background:#156A86;
	}
`;

/*
 *
 */
export function Hero ({ title, subtitle, ctaText, ctaLink }) {
	return (
		<Root>
			<Title>{title}</Title>
			<Subtitle>{subtitle}</Subtitle>
			{ctaLink ? <LinkCta target="_blank" href={ctaLink}>{ctaText}</LinkCta> : null}
		</Root>
	);
}

export default Hero;
