/*
 *
 */
import React from 'react';
import styled from 'styled-components';

/*
 * Styles
 */
const List = styled.ul`
	margin:0;
	padding:0;
	list-style:none;
	display:flex;
	flex-wrap:wrap;
	justify-content:center;
`;


const Item = styled.li`
	margin:0;
	padding:0 1rem 0 1rem;
	width:50%;
	box-sizing:border-box;

	@media (${props => props.theme.media.tablet}) {
		width:20%;
	}
`;

const PressCompany = styled.a`
	display:block;
`;

/*
 *
 */

export function Press ({ press=[] }) {
	return (
		<List>
			{press.map((p, i) => (
				<Item key={i}>
					<PressCompany href={p.url} target="_blank" title={p.name} rel="nofollow noopener noreferrer">
						<img src={p.logo} alt={p.name} />
					</PressCompany>
				</Item>
			))}
		</List>
	);
}

export default Press;
