/*
 *
 */
import React from 'react';
import styled from 'styled-components';

const Text = styled.div`
	margin:0 auto;

	width:90%;
	max-width:48em;
	font-size:1.5em;
	text-align:center;

	h2, h3 {
		margin:2em 0 1em;

		&:first-child {
			margin-top:0;
		}

		&:last-child {
			margin-bottom:0;
		}

		font-weight:500;
		text-align:center;
	}

	h2 {
		font-size:2rem;
		color:#1b83a4;
	}

	h3 {
		font-size:1.8rem;
	}

	@media (${props => props.theme.media.tablet}) {
		h2 {
			font-size:3.2rem;
		}

		h3 {
			font-size:2.0rem;
		}
	}

	p {
		margin:1.33em 0;

		&:first-child {
			margin-top:0;
		}

		&:last-child {
			margin-bottom:0;
		}

		font-size:1.5rem;
		line-height:1.5em;

	}

	& a {
		color:#1b83a4;
	}

`;

/*
 *
 */
export default function text ({ children }) {

	return (
		<Text>
			{children}
		</Text>
	);
}
