//
import React from "react"

//
import Layout from '../layout/index';
import PayconiqBancontact from 'routes/payconiq/index.js';

/*
 *
 */
export default (props) => (
	<Layout {...props}>
		<PayconiqBancontact />
	</Layout>
);